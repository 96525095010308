import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAddressBook,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAnglesLeft,
  faAnglesRight,
  faAnglesUp,
  faArrowDown,
  faArrowLeft,
  faArrowRightToBracket,
  faArrowsRotate,
  faArrowsUpDownLeftRight,
  faArrowUpWideShort,
  faAward,
  faBackward,
  faBackwardFast,
  faBan,
  faBars,
  faBook,
  faBookBookmark,
  faBox,
  faBoxArchive,
  faBoxesStacked,
  faBuilding,
  faBuildingColumns,
  faBullhorn,
  faCalendar,
  faCalendarCheck,
  faCalendarDay,
  faCalendarDays,
  faCalendarPlus,
  faCaretDown,
  faCaretRight,
  faCaretUp,
  faCartShopping,
  faCashRegister,
  faChalkboard,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircleDot,
  faCircleInfo,
  faCircleNotch,
  faClipboard,
  faClock,
  faClockRotateLeft,
  faCloudArrowDown,
  faCog,
  faComment,
  faCommentDollar,
  faComments,
  faCopy,
  faCrown,
  faDownload,
  faExclamationCircle,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faFile,
  faFileArrowUp,
  faFileCircleCheck,
  faFileContract,
  faFileInvoice,
  faFileInvoiceDollar,
  faFileLines,
  faFilePen,
  faFileShield,
  faFileSignature,
  faFilter,
  faFilterCircleXmark,
  faFlagCheckered,
  faFloppyDisk,
  faFolderOpen,
  faGhost,
  faGlobe,
  faGraduationCap,
  faHand,
  faHandHoldingDollar,
  faHandshakeSimple,
  faHeartCrack,
  faHorseHead,
  faHourglassHalf,
  faHouseUser,
  faIdCard,
  faIdCardClip,
  faInfo,
  faInfoCircle,
  faKey,
  faLandMineOn,
  faLocationDot,
  faLocationPin,
  faLock,
  faLockOpen,
  faMagnifyingGlass,
  faMessage,
  faMinus,
  faMoneyBillTransfer,
  faMoon,
  faO,
  faPaperPlane,
  faPencil,
  faPenToSquare,
  faPeopleLine,
  faPeopleRoof,
  faPersonCircleQuestion,
  faPersonDigging,
  faPiggyBank,
  faPlay,
  faPlus,
  faPlusCircle,
  faPowerOff,
  faPrint,
  faQuestionCircle,
  faQuoteRight,
  faReceipt,
  faRightFromBracket,
  faRotateRight,
  faSackDollar,
  faSave,
  faScrewdriverWrench,
  faShare,
  faShieldHalved,
  faSignHanging,
  faSitemap,
  faSkull,
  faSkullCrossbones,
  faSort,
  faSortDown,
  faSortUp,
  faSquare,
  faSquareCheck,
  faStamp,
  faStopwatch,
  faSun,
  faTable,
  faTag,
  faTags,
  faThumbTack,
  faTicket,
  faTimes,
  faTimesCircle,
  faTrashAlt,
  faTrashArrowUp,
  faTrashCan,
  faTruck,
  faTruckArrowRight,
  faUnlockKeyhole,
  faUpload,
  faUser,
  faUserClock,
  faUserGraduate,
  faUserGroup,
  faUserNurse,
  faUserPen,
  faUserPlus,
  faUsers,
  faUserTie,
  faWallet,
  faX,
} from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

library.add(
  faAddressBook,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAnglesLeft,
  faAnglesRight,
  faAnglesUp,
  faArrowDown,
  faArrowLeft,
  faArrowRightToBracket,
  faArrowsRotate,
  faArrowsUpDownLeftRight,
  faArrowUpWideShort,
  faAward,
  faBackward,
  faBackwardFast,
  faBan,
  faBars,
  faBook,
  faBookBookmark,
  faBox,
  faBoxArchive,
  faBoxesStacked,
  faBuilding,
  faBuildingColumns,
  faBullhorn,
  faCalendar,
  faCalendarCheck,
  faCalendarDay,
  faCalendarDays,
  faCalendarPlus,
  faCaretDown,
  faCaretRight,
  faCaretUp,
  faCartShopping,
  faCashRegister,
  faChalkboard,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircleDot,
  faCircleInfo,
  faCircleNotch,
  faClipboard,
  faClock,
  faClockRotateLeft,
  faCloudArrowDown,
  faCog,
  faComment,
  faCommentDollar,
  faComments,
  faCopy,
  faCrown,
  faDownload,
  faExclamationCircle,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faFile,
  faFileArrowUp,
  faFileCircleCheck,
  faFileContract,
  faFileInvoice,
  faFileInvoiceDollar,
  faFileLines,
  faFilePen,
  faFileShield,
  faFileSignature,
  faFilter,
  faFilterCircleXmark,
  faFlagCheckered,
  faFloppyDisk,
  faFolderOpen,
  faGhost,
  faGlobe,
  faGraduationCap,
  faHand,
  faHandHoldingDollar,
  faHandshakeSimple,
  faHeartCrack,
  faHorseHead,
  faHourglassHalf,
  faHouseUser,
  faIdCard,
  faIdCardClip,
  faInfo,
  faInfoCircle,
  faKey,
  faLandMineOn,
  faLocationDot,
  faLocationPin,
  faLock,
  faLockOpen,
  faMagnifyingGlass,
  faMessage,
  faMinus,
  faMoneyBillTransfer,
  faMoon,
  faO,
  faPaperPlane,
  faPencil,
  faPenToSquare,
  faPeopleLine,
  faPeopleRoof,
  faPersonCircleQuestion,
  faPersonDigging,
  faPiggyBank,
  faPlay,
  faPlus,
  faPlusCircle,
  faPowerOff,
  faPrint,
  faQuestionCircle,
  faQuoteRight,
  faReceipt,
  faRightFromBracket,
  faRotateRight,
  faSackDollar,
  faSave,
  faScrewdriverWrench,
  faShare,
  faShieldHalved,
  faSignHanging,
  faSitemap,
  faSkull,
  faSkullCrossbones,
  faSort,
  faSortDown,
  faSortUp,
  faSquare,
  faSquareCheck,
  faStamp,
  faStopwatch,
  faSun,
  faTable,
  faTag,
  faTags,
  faThumbTack,
  faTicket,
  faTimes,
  faTimesCircle,
  faTrashAlt,
  faTrashArrowUp,
  faTrashCan,
  faTruck,
  faTruckArrowRight,
  faUnlockKeyhole,
  faUpload,
  faUser,
  faUserClock,
  faUserGraduate,
  faUserGroup,
  faUserNurse,
  faUserPen,
  faUserPlus,
  faUsers,
  faUserTie,
  faWallet,
  faX,
  faWhatsapp,
);
